import React, { FC as FC_, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Section, FormGroup, Input, MaskedInput, AddressAutocomplete, IconsSolid,
  Toast
} from '@jkhy/vsg-design-system';

import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import PageFieldExtended_ from '../../Page/PageHelpers/PageFieldExtended';
import PartyCheck_ from '../../../../data/models/PartyCheck';
import { AppState as AppState_ } from '../../../../redux/AppState';
import PickList from '../../../../components/PickList/PickList';
import { scrollToError, validateAddressNoPoBox, isGoogleUnavailable } from '../../../../utils/Helper';
import { buildBusinessPartyMatchAddress } from '../../../../utils/Address';
import { setLoader } from '../../../../redux/actions/Loading';
import regexCheck from '../../../../utils/Validator';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_, AddressDetails as AddressDetails_ } from '../../../../utils/Types';
import {
  ExternalPages, ExternalPageSectionName, PartyType, ValidationRegexConstants
} from '../../../../utils/Enums';
import QdServiceApi_ from '../../../../data/api/QDServiceApi';

import Message from '../../../../utils/Messages';
import InputMasksConstants from '../../../../utils/InputMasksConstants';
import QDPartyMatchPars_ from '../../../../data/models/QDPartyMatchPars';
import Messages from '../../../../utils/Messages';

const QD_PARTY_MATCH_PROPERTY_NAMES = {
  BUSINESS_NAME: 'FullName',
  BUSINESS_TIN: 'TIN',
  ADDRESS: 'Address',
  STREET: 'Street',
  ZIP: 'Zip',
  CITY: 'City',
  STATE: 'State',
};

export interface BusinessBorrowerMatchProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, PartyCheck_>[],
    holder: QdApplicationHolder_,
    ownerCheck: PartyCheck_,
    sequence: string,
    fromGuarantor: boolean
  ) => void;
}

const BusinessBorrowerMatch: FC_<BusinessBorrowerMatchProps> = (props: BusinessBorrowerMatchProps) => {
  const { holder, loading, modelState, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
  }));

  const {
    BUSINESS_NAME, BUSINESS_TIN, ADDRESS, STREET, CITY, STATE, ZIP,
  } = QD_PARTY_MATCH_PROPERTY_NAMES;

  const [businessMatchPars, setBusinessMatchPars] = useState(new QDPartyMatchPars_());

  const section = modelState?.ModelHolder?.ApplicationSections?.find(s => s.SectionName === ExternalPageSectionName.BusinessPartyMatch);
  const [invalidSetOfFields, setInvalidSetOfFields] = useState([]);
  const [borrowerCheck, setBorrowerCheck] = useState(holder?.BorrowerCheck);
  const noGoogleAPI = isGoogleUnavailable();

  const updateState = (value: string, property: string) => {
    if (property === ADDRESS) {
      const updatedPartyMatchPars = value
        ? { ...businessMatchPars, [property]: value, }
        : {
          ...businessMatchPars,
          Address: '',
          Street: '',
          City: '',
          State: '',
          Zip: '',
        };
      setBusinessMatchPars(updatedPartyMatchPars);
      return;
    }

    setBusinessMatchPars({ ...businessMatchPars, [property]: value?.trim(), });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
    e.preventDefault();

    const {
      target: { value, },
    } = e;
    updateState(value, property);
  };

  const onStateChange = (item: any, property: string) => {
    updateState(item?.value, property);
  };

  const onAddressSelect = (addressDetails: AddressDetails_) => {
    const {
      fullAddress, streetAddress, city, state, zipCode,
    } = addressDetails;

    setBusinessMatchPars({
      ...businessMatchPars,
      Address: fullAddress?.trim(),
      Street: streetAddress?.trim(),
      City: city?.trim(),
      State: state?.trim(),
      Zip: zipCode?.trim(),
    });
  };

  const dispatch = useDispatch();

  const getTinInvalidMessage = () => (!businessMatchPars.TIN ? Message.REQUIRED_FIELD : Message.INVALID_TIN_FORMAT);
  const getZipInvalidMessage = () => (!businessMatchPars.Zip ? Message.REQUIRED_FIELD : Message.INVALID_ZIP_FORMAT);
  const validateZipFormat = (ZipCode: string) => {
    const regexExpNoPoBox = new RegExp(ValidationRegexConstants.ZipCode);
    return regexExpNoPoBox.test(ZipCode);
  };

  const validate = () => {
    const invalidSetOfF = [];

    if (!businessMatchPars.FullName) invalidSetOfF.push(BUSINESS_NAME);

    if (!businessMatchPars.TIN) invalidSetOfF.push(BUSINESS_TIN);
    else {
      const { IsValid, } = regexCheck({
        regexStr: ValidationRegexConstants.TIN_SSN,
        value: businessMatchPars.TIN,
        errorMessage: Message.INVALID_TIN_FORMAT,
      });

      if (!IsValid) invalidSetOfF.push(BUSINESS_TIN);
    }

    if (noGoogleAPI) {
      // Google API is NOT available
      if (!businessMatchPars.Street || !validateAddressNoPoBox(businessMatchPars.Street)) invalidSetOfF.push(STREET);
      if (!businessMatchPars.City) invalidSetOfF.push(CITY);
      if (!businessMatchPars.State) invalidSetOfF.push(STATE);
      if (!businessMatchPars.Zip || !validateZipFormat(businessMatchPars.Zip)) invalidSetOfF.push(ZIP);
    } else if (
      (!businessMatchPars.Address // Google API is avaialble
        && !businessMatchPars.Street
        && !businessMatchPars.City
        && !businessMatchPars.State
        && !businessMatchPars.Zip)
      || !validateAddressNoPoBox(businessMatchPars.Address)
    ) {
      invalidSetOfF.push(ADDRESS);
    }

    setInvalidSetOfFields(invalidSetOfF);

    return invalidSetOfF.length === 0;
  };

  const onSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { Business: business, } = borrowerCheck;

    if (validate()) {
      dispatch(setLoader(true));
      businessMatchPars.GUIDQDApplication = holder?.GUIDQDApplication;
      businessMatchPars.PartyType = PartyType.Business;
      const { Result, } = await QdServiceApi_.qdPartyMatch(businessMatchPars);

      business.PartyMatchDone = true;
      business.FullName = businessMatchPars.FullName;
      business.TIN = businessMatchPars.TIN;
      if (Result?.IdParty) business.IdParty = Result?.IdParty;

      const newAddress = buildBusinessPartyMatchAddress(businessMatchPars, business);
      const newBusinessAddresses = [newAddress];

      const modifiedBorrowerCheck = {
        ...borrowerCheck,
        Business: business,
        BusinessAddresses: newBusinessAddresses,
      };

      setBorrowerCheck(modifiedBorrowerCheck);

      await props.onSubmit([], holder, modifiedBorrowerCheck, ExternalPages.BusinessPartyMatch, false);
      dispatch(setLoader(false));
    } else {
      setTimeout(() => {
        scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
      }, 300);
    }
  };

  const IsValid = field => !invalidSetOfFields.find(invalidField => invalidField === field);

  const sameTinWarning = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    e.preventDefault();

    const { BorrowerP: { Personal: { TIN: personalSSN, PercentOwnership, }, }, } = holder;
    const { TIN, } = businessMatchPars;

    const hasLessThen100Percent = (PercentOwnership ?? 0) < 1;
    if (personalSSN === TIN && hasLessThen100Percent) {
      Toast.warning(Messages.BUSINESS_TIN_SAME_AS_MAIN_APPLICANT);
    }
  }

  return (
    <>
      {!loading && (
        <form id={`form-${ExternalPages.BusinessPartyMatch}`} onSubmit={onSave}>
          <Section
            title="Business"
            headerText={`To save you time in filling out the business information,
                          please enter the following information
                          so we can try to locate your business details.`}
            dataUI={`${section.SubSections[0]?.Code}-section`}
          >
            <FormGroup
              className="mb-2"
              htmlFor="businessName"
              label="Business Name"
              dataUI="fgBusinessName"
              isRequired
              isValid={IsValid(BUSINESS_NAME)}
              validationMessage={Message.REQUIRED_FIELD}
            >
              <Input type="text" name="businessName" id="businessName" dataUI="txtBusinessName" onChange={e => onChange(e, BUSINESS_NAME)} />
            </FormGroup>
            <FormGroup
              className="mb-2"
              htmlFor="businessTIN"
              isRequired
              label="Business TIN"
              dataUI="fgBusinessTIN"
              isValid={IsValid(BUSINESS_TIN)}
              validationMessage={getTinInvalidMessage()}
            >
              <MaskedInput
                id="businessTIN"
                dataUI="txtBusinessTIN"
                className="text-left"
                name="businessTIN"
                mask={InputMasksConstants.SSN_TAX_ID}
                pattern={null}
                onChange={e => onChange(e, BUSINESS_TIN)}
                onBlur={e => sameTinWarning(e)}
              />
            </FormGroup>
            <FormGroup
              isHidden={noGoogleAPI}
              className="mb-2"
              htmlFor="businessAddress"
              label="Business Address"
              dataUI="fgbusinessAddress"
              isRequired
              isValid={IsValid(ADDRESS) && validateAddressNoPoBox(businessMatchPars.Address)}
              validationMessage={validateAddressNoPoBox(businessMatchPars.Address) ? Message.REQUIRED_FIELD : Message.INVALID_STREET_ADDRESS}
            >
              <AddressAutocomplete
                type="text"
                name="businessAddress"
                id="businessAddress"
                dataUI="businessAddress"
                value={businessMatchPars?.Address}
                onBlur={() => IsValid(ADDRESS)}
                onChange={(addressDetails: any) => updateState(addressDetails, ADDRESS)}
                onAddressSelect={(addressDetails: any) => {
                  onAddressSelect(addressDetails);
                }}
              />
            </FormGroup>

            {noGoogleAPI && (
              <>
                <FormGroup
                  className="mb-2"
                  htmlFor="businessStreet"
                  isRequired
                  label="Street Address"
                  dataUI="fgBusinessStreet"
                  isValid={IsValid(STREET)}
                  validationMessage={validateAddressNoPoBox(businessMatchPars.Street) ? Message.REQUIRED_FIELD : Message.INVALID_STREET_ADDRESS}
                >
                  <Input id="businessStreet" name="businessStreet" dataUI="txtBusinessStreet" onChange={e => onChange(e, STREET)} type="text" />
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  htmlFor="businessCity"
                  isRequired
                  label="City"
                  dataUI="fgBusinessCity"
                  isValid={IsValid(CITY)}
                  validationMessage={Message.REQUIRED_FIELD}
                >
                  <Input id="businessCity" name="businessCity" dataUI="txtBusinessCity" onChange={e => onChange(e, CITY)} type="text" />
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  htmlFor="businessState"
                  isRequired
                  label="State"
                  dataUI="fgBusinessState"
                  isValid={IsValid(STATE)}
                  validationMessage={Message.REQUIRED_FIELD}
                >
                  <PickList
                    dsSelectWrapper
                    disabled={false}
                    value={businessMatchPars.State}
                    id="businessState"
                    stateLess={false}
                    initialData={[]}
                    onChange={e => onStateChange(e, STATE)}
                    ListName="States"
                    ListType="LtItems"
                    dataUI="businessState"
                  />
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  htmlFor="businessZipCode"
                  isRequired
                  label="Zip"
                  dataUI="fgBusinessZipCode"
                  isValid={IsValid(ZIP)}
                  validationMessage={getZipInvalidMessage()}
                >
                  <MaskedInput
                    id="businessZipCode"
                    dataUI="txtBusinessZipCode"
                    name="businessZipCode"
                    type="zipCode"
                    mask={InputMasksConstants.ZIP_CODE}
                    pattern={null}
                    onChange={e => onChange(e, ZIP)}
                    icon={IconsSolid.faMapPin}
                  />
                </FormGroup>
              </>
            )}
          </Section>
        </form>
      )}
    </>
  );
};

export default BusinessBorrowerMatch;
