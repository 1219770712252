import PageField_ from '../../../../data/models/PageField';
import validateFormat from '../../../../utils/Validator';
import Message from '../../../../utils/Messages';
import PageFieldComponent_ from '../../../../data/models/Component';

interface IIsValid<T, T1> {
  IsValid: (holder: T, subHolder: T1) => boolean;
}

export default class PageFieldExtended<THolder, THolderSubSet> extends PageField_ implements IIsValid<THolder, THolderSubSet> {
  Component: PageFieldComponent_ | undefined;

  IsDisabled: boolean;

  IsReadOnly?: boolean;

  ObjectType: string;

  ObjectIndex: string;

  ObjectProperty: string;

  ObjectPropertyStr: string;

  ObjectPropertyType: string;

  SortOrder: number;

  dataUI: string;

  IsValid: (holder: THolder, holderSubset: THolderSubSet, pageField?: PageFieldExtended<THolder, THolderSubSet>) => boolean = (
    holder,
    subHolder,
    pageField
  ) => {
    if (pageField.IsHidden) return true;

    if (pageField.IsHiddenCalculated(holder, subHolder, pageField)) return true;

    let result = subHolder[pageField.ObjectProperty];
    if (pageField.ObjectType) {
      result = pageField.ObjectIndex
        ? subHolder[pageField.ObjectType]?.[pageField.ObjectIndex]?.[pageField.ObjectProperty]
        : subHolder[pageField.ObjectType]?.[pageField.ObjectProperty];
    }

    if (typeof result === 'boolean') result = `${result}`;
    if (typeof result === 'number' && result === 0) result = `${result}`;
    // eslint-disable-next-line no-undef
    if (typeof result === 'bigint' && result === BigInt(0)) result = `${result}`;

    if (!!result && pageField.Component) {
      const {
        Component: {
          type, inputType, regexStr, errorMessage,
        },
      } = pageField;
      if (!!regexStr || inputType === 'email') {
        const { IsValid, InvalidMessage, } = validateFormat({
          inputType,
          regexStr,
          value: `${result}`,
          errorMessage,
        });
        const pf = pageField;
        pf.ValidationMessage = InvalidMessage;
        return IsValid;
      }

      if (type === 'datepicker') result = result !== 'Invalid date';
    }

    return !pageField.Required ? true : !!result;
  };

  IsHiddenCalculated?: (
    valuesObj_: THolder,
    subHolder?: THolderSubSet,
    pageField?: PageFieldExtended<THolder, THolderSubSet>
  ) => boolean = (): boolean => false;

  ValidationMessage?: string = Message.REQUIRED_FIELD;

  IsInvalidResult: boolean;

  IsMultipleForm: boolean;

  FormComponentsVisibility?: (
    valuesObj_: THolder,
    subHolder?: THolderSubSet,
    pageField?: PageFieldExtended<THolder, THolderSubSet>
  ) => PageFieldComponent_[];

  static createInstance = (props: any) => ({ ...new PageFieldExtended(), ...props, });
}
