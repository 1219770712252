import React, { FC as FC_, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import Borrower_ from '../../../../../data/models/Borrower';
import Page from '../../../Page/Page';
import OtherInfromationSettings from './OtherInformationSettings';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import { GuarantorPages, GuarantorPageSectionName_ } from '../../../../../utils/Enums';
import { booleanStringComparison, scrollToError } from '../../../../../utils/Helper';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import BusinessOtherInformation_ from '../../../../../data/models/PageFields/BusinessInformation/Other/Other';
import PageField from '../../../../../data/models/PageField';

export interface CoBorrowerAddNowInformationProps {
  onSave: (holder: CoBorrowerAddNowHolder_, currentSectionName: string) => void;
  onCancel: () => void;
}

const GuarantorInformation: FC_<CoBorrowerAddNowInformationProps> = (props: CoBorrowerAddNowInformationProps) => {
  const { loading, modelState, addNowHolder, } = useSelector<
    AppState_,
    {
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
      addNowHolder: CoBorrowerAddNowHolder_;
    }
  >(state => ({
    loading: state.loading,
    modelState: state.modelState,
    addNowHolder: state.coBorrowerAddNowState?.CoBorrowerAddNowHolder,
  }));

  const [coBorrower, setCoBorrower] = useState(addNowHolder?.CoBorrower);
  const coborrowerSections = modelState?.ModelHolder?.CoBorrowerSections;

  const section = coborrowerSections?.find(s => (s.SectionName || '') === GuarantorPageSectionName_.BusinessOtherInformation);
  const [subSection] = section?.SubSections;

  const pageFieldHolder = modelState?.ModelHolder?.CoBorrowerPageField;

  const otherInformationPF: BusinessOtherInformation_ = useMemo(() => {
    // AverageDeposits is the last field in page field settings for this section;
    // Use sortorder for custom fields
    const { SortOrder: lastFieldOrder, } = pageFieldHolder?.BusinessInformation?.OtherInformation?.AverageDeposits;

    return {
      ...pageFieldHolder?.BusinessInformation?.OtherInformation,
      // Custom fields
      ExistingAccount: { ...new PageField(), FieldName: 'ExistingAccount', SortOrder: lastFieldOrder + 1, },
      CheckingAccountNumber: { ...new PageField(), FieldName: 'CheckingAccountNumber', SortOrder: lastFieldOrder + 2, },
      ReCheckingAccountNumber: { ...new PageField(), FieldName: 'ReCheckingAccountNumber', SortOrder: lastFieldOrder + 3, },
    };
  }, [pageFieldHolder]);

  const pageSettings = useMemo(() => {
    return new OtherInfromationSettings([otherInformationPF], [subSection]);
  }, [otherInformationPF, subSection]);

  const updateCoBorrowerAddNow = (pageField: PageFieldExtended_<CoBorrowerAddNowHolder_, Borrower_>, value: string) => {
    coBorrower[pageField.ObjectProperty] = value;
    setCoBorrower({ ...coBorrower, });
  };

  const onSubmit = async (invalidPageFields: PageFieldExtended_<CoBorrowerAddNowHolder_, Borrower_>[], e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (invalidPageFields.length === 0) {
      const coBorrwerAddNowHolder: CoBorrowerAddNowHolder_ = { ...addNowHolder, CoBorrower: coBorrower, };

      const { IsExistingCustomerFlag, CheckingAccountNumber, } = coBorrwerAddNowHolder?.CoBorrower;

      // Reset checking account number value
      if (!booleanStringComparison(IsExistingCustomerFlag) && CheckingAccountNumber) {
        coBorrwerAddNowHolder.CoBorrower.CheckingAccountNumber = null;
      }

      await props.onSave(coBorrwerAddNowHolder, GuarantorPageSectionName_.BusinessOtherInformation);
    } else {
      setTimeout(() => {
        scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
      }, 300);
    }
  };

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={updateCoBorrowerAddNow}
          holder={addNowHolder}
          subHolder={coBorrower}
          onSubmit={onSubmit}
          formIdentifier={`form-${GuarantorPages.BusinessOtherInformation}`}
        />
      )}
    </>
  );
};
export default GuarantorInformation;
