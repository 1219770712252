/* eslint no-undef: off */
/* eslint array-callback-return: off */
import React, { FC as FC_, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Section, FormGroup, Input, MaskedInput, Radio, Datepicker
} from '@jkhy/vsg-design-system';

import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import PageFieldExtended_ from '../../Page/PageHelpers/PageFieldExtended';
import PartyCheck_ from '../../../../data/models/PartyCheck';
import { AppState as AppState_ } from '../../../../redux/AppState';
import YourInformation_ from '../../../../data/models/YourInformation';
import {
  scrollToError, dateFormat, getHoverHelpPageFieldValue, booleanStringComparison, isNullOrUndefined
} from '../../../../utils/Helper';
import { setLoader } from '../../../../redux/actions/Loading';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import { ExternalPages, ExternalPageSectionName, PartyType } from '../../../../utils/Enums';
import QdServiceApi_ from '../../../../data/api/QDServiceApi';
import { ISaveHolder as ISaveHolder_ } from '../../../../redux/actions/Holder';
import Message from '../../../../utils/Messages';
import QDPartyMatchPars_ from '../../../../data/models/QDPartyMatchPars';
import QDPartyMatchResult_ from '../../../../data/models/QDPartyMatchResult';
import InputMasksConstants from '../../../../utils/InputMasksConstants';
import { validatePartyMatchTIN } from '../../../../utils/Validator';

const YOUR_INFORMATION_STATES = {
  HAS_DONE_BUSINESS: 0,
  GATHER_MATCH_INFORMATION: 1,
  CONFIRM_ADDRESS: 2,
  GREATE_PROCEED: 3,
};

const PAGE_FIELDS_NAMES = {
  FIRST_NAME: 'FirstName',
  LAST_NAME: 'LastName',
  DATE_OF_BIRTH: 'DateOfBirth',
  TIN: 'TIN',
  ADDRESS: 'Address',
  ADDRESS_CONFIRMED: 'AddressConfirmed',
  HAS_DONE_BUSINESS: 'HasDoneBussinessPreviosly',
};

export interface YourInformationProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, PartyCheck_>[],
    holder: QdApplicationHolder_,
    ownerCheck: PartyCheck_,
    sequence: string,
    fromGuarantor: boolean
  ) => void;
}

const YourInformation: FC_<YourInformationProps> = (props: YourInformationProps) => {
  const { holder, loading, modelState, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
  }));

  const {
    ApplicationSections,
    ApplicationPageField: {
      PersonalPartyMatch: {
        General: { PartyMatchQuestion, },
      },
    },
  } = modelState?.ModelHolder;
  const [yourInformationState, setYourInformationState] = useState(YOUR_INFORMATION_STATES.HAS_DONE_BUSINESS);
  const [yourInformation, setYourInformation] = useState(new YourInformation_());
  const section = ApplicationSections?.find(s => s.SectionName === ExternalPageSectionName.PersonalPartyMatch);
  const [invalidSetOfFields, setInvalidSetOfFields] = useState([]);
  const [invalidTINMessage, setInvalidTINMessage] = useState('');
  const [ownerCheck, setOwnerCheck] = useState(holder?.OwnerCheck);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
    e.preventDefault();

    const {
      target: { value, },
    } = e;
    yourInformation[property] = value;
    setYourInformation(yourInformation);
  };

  const onHasDoneBusinesChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
    const {
      target: { value, },
    } = e;
    const hasDoneBussiness = booleanStringComparison(value);
    yourInformation[property] = hasDoneBussiness;
    setYourInformation(yourInformation);

    if (hasDoneBussiness) setYourInformationState(YOUR_INFORMATION_STATES.GATHER_MATCH_INFORMATION);
    else setYourInformationState(YOUR_INFORMATION_STATES.HAS_DONE_BUSINESS);
  };

  const onDateChange = (e: any) => {
    yourInformation.DateOfBirth = dateFormat(e.target?.value);
    setYourInformation(yourInformation);
  };

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, },
    } = e;
    yourInformation.AddressConfirmed = booleanStringComparison(value);
    setYourInformation(yourInformation);
  };

  const isAdult = value => moment().diff(value, 'year') >= 18;
  const validate = () => {
    const invalidSetOfF = [];
    const {
      FIRST_NAME, LAST_NAME, DATE_OF_BIRTH, TIN, ADDRESS_CONFIRMED, HAS_DONE_BUSINESS,
    } = PAGE_FIELDS_NAMES;

    if (yourInformationState === YOUR_INFORMATION_STATES.HAS_DONE_BUSINESS) {
      const { Required, } = PartyMatchQuestion;
      const isValid = !Required || (Required && !isNullOrUndefined(yourInformation.HasDoneBussinessPreviosly));
      if (!isValid) {
        invalidSetOfF.push(HAS_DONE_BUSINESS);
        setInvalidSetOfFields(invalidSetOfF);
      }
      return isValid;
    }

    if (!yourInformation.FirstName) invalidSetOfF.push(FIRST_NAME);
    if (!yourInformation.LastName) invalidSetOfF.push(LAST_NAME);
    if (!yourInformation.DateOfBirth || !isAdult(yourInformation.DateOfBirth)) invalidSetOfF.push(DATE_OF_BIRTH);
    const { IsValid: isTINValid, InvalidMessage: tinMessage, } = validatePartyMatchTIN(yourInformation.TIN, PartyType.Personal, holder, false);

    if (!isTINValid) invalidSetOfF.push(TIN);
    setInvalidTINMessage(tinMessage);

    if (yourInformationState === YOUR_INFORMATION_STATES.CONFIRM_ADDRESS && yourInformation.AddressConfirmed == null) {
      invalidSetOfF.push(ADDRESS_CONFIRMED);
    }

    setInvalidSetOfFields(invalidSetOfF);
    return invalidSetOfF.length === 0;
  };
  const getDateInvalidMessage = () => (!yourInformation.DateOfBirth ? Message.REQUIRED_FIELD : Message.INVALID_AGE);

  const dispatch = useDispatch();
  const updateHolder = () => {
    const personal = {
      ...holder.BorrowerP.Personal,
      PartyMatchDone: ownerCheck.Personal.PartyMatchDone,
      IdParty: ownerCheck.Personal.IdParty,
      FirstName: ownerCheck.Personal.FirstName,
      LastName: ownerCheck.Personal.LastName,
      DOB: ownerCheck.Personal.DOB,
      TIN: ownerCheck.Personal.TIN,
    };
    const borrowerP = { ...holder.BorrowerP, Personal: personal, };

    const saveHolderAction: ISaveHolder_ = {
      holderState: {
        Holder: { ...holder, OwnerCheck: ownerCheck, BorrowerP: borrowerP, },
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };
    dispatch(saveHolderAction);
  };

  const onSubmit = async () => {
    updateHolder();
    await props.onSubmit([], holder, ownerCheck, ExternalPages.PersonalPartyMatch, false);
  };

  const getAddress = (pars: QDPartyMatchResult_) => {
    const {
      Street, State, Zip, City,
    } = pars;
    return `${Street}, ${City}, ${State} ${Zip}`;
  };

  const onSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { Personal: personal, } = ownerCheck;
    switch (yourInformationState) {
      case YOUR_INFORMATION_STATES.HAS_DONE_BUSINESS:
        if (validate()) {
          personal.PartyMatchDone = true;
          setOwnerCheck(ownerCheck);

          setLoader(true);
          await onSubmit();
          setLoader(false);
        } else {
          setTimeout(() => {
            scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
          }, 300);
        }
        break;
      case YOUR_INFORMATION_STATES.GATHER_MATCH_INFORMATION:
        setLoader(true);
        if (validate()) {
          const pars: QDPartyMatchPars_ = {
            PartyType: personal?.PartyType,
            GUIDQDApplication: holder?.GUIDQDApplication,
            TIN: yourInformation.TIN,
            BirthDate: yourInformation.DateOfBirth,
            FirstName: yourInformation.FirstName?.trim(),
            LastName: yourInformation.LastName?.trim(),
          };

          const { Result, } = await QdServiceApi_.qdPartyMatch(pars);

          personal.PartyMatchDone = true;
          personal.FirstName = yourInformation.FirstName;
          personal.LastName = yourInformation.LastName;
          personal.DOB = yourInformation.DateOfBirth;
          personal.TIN = yourInformation.TIN;

          setOwnerCheck(ownerCheck);

          const address = getAddress(Result);
          if (Result?.IdParty && address) {
            yourInformation.Address = address;
            yourInformation.IdPartyMatch = Result?.IdParty;

            setYourInformation(yourInformation);
            setYourInformationState(YOUR_INFORMATION_STATES.CONFIRM_ADDRESS);
          } else {
            setLoader(true);
            await onSubmit();
            setLoader(false);
          }
        } else {
          setTimeout(() => {
            scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
          }, 300);
        }
        setLoader(false);
        break;
      case YOUR_INFORMATION_STATES.CONFIRM_ADDRESS:
        if (validate()) {
          if (yourInformation.AddressConfirmed) {
            personal.IdParty = yourInformation.IdPartyMatch;
            personal.PartyMatchConfirmedByUser = true;
            setOwnerCheck(ownerCheck);
            setYourInformationState(YOUR_INFORMATION_STATES.GREATE_PROCEED);
          } else {
            setLoader(true);
            await onSubmit();
            setLoader(false);
          }
        }
        break;
      case YOUR_INFORMATION_STATES.GREATE_PROCEED:
        setLoader(true);
        await onSubmit();
        setLoader(false);
        break;
      default:
        break;
    }
  };

  const IsValid = field => !invalidSetOfFields.find(invalidField => invalidField === field);
  const {
    FIRST_NAME, LAST_NAME, DATE_OF_BIRTH, TIN, ADDRESS_CONFIRMED, HAS_DONE_BUSINESS,
  } = PAGE_FIELDS_NAMES;

  return (
    <>
      {!loading && (
        <form id={`form-${ExternalPages.PersonalPartyMatch}`} onSubmit={onSave}>
          {(yourInformationState === YOUR_INFORMATION_STATES.GATHER_MATCH_INFORMATION
            || yourInformationState === YOUR_INFORMATION_STATES.HAS_DONE_BUSINESS) && (
            <Section
              title="Your Information"
              headerText="Let us see if we can locate your information. Please enter the following information."
              dataUI={`${section.SubSections[0]?.Code}-section`}
            >
              <FormGroup
                className="mb-2"
                label={PartyMatchQuestion.Label}
                checkboxOrRadio
                isBold={PartyMatchQuestion.IsBold}
                isRequired={PartyMatchQuestion.Required}
                hoverHelp={getHoverHelpPageFieldValue(PartyMatchQuestion)}
                isValid={IsValid(HAS_DONE_BUSINESS)}
                validationMessage={Message.REQUIRED_FIELD}
                dataUI="has-done-business-before"
                htmlFor={PartyMatchQuestion.FieldName}
              >
                <Radio
                  htmlFor={`${PartyMatchQuestion.FieldName}-Yes`}
                  id={`${PartyMatchQuestion.FieldName}-Yes`}
                  value="true"
                  name={PartyMatchQuestion.FieldName}
                  onChange={e => onHasDoneBusinesChange(e, HAS_DONE_BUSINESS)}
                  dataUI="has-done-business-before-yes"
                  className="d-inline-block"
                >
                  Yes
                </Radio>
                <Radio
                  htmlFor={`${PartyMatchQuestion.FieldName}-No`}
                  id={`${PartyMatchQuestion.FieldName}-No`}
                  value="false"
                  name={PartyMatchQuestion.FieldName}
                  onChange={e => onHasDoneBusinesChange(e, HAS_DONE_BUSINESS)}
                  dataUI="has-done-business-before-no"
                  className="d-inline-block"
                >
                  No
                </Radio>
              </FormGroup>

              {yourInformation.HasDoneBussinessPreviosly && (
                <>
                  <FormGroup
                    className="mb-2"
                    htmlFor="firstName"
                    isRequired
                    label="First Name"
                    dataUI="fgFirstName"
                    isValid={IsValid(FIRST_NAME)}
                    validationMessage={Message.REQUIRED_FIELD}
                  >
                    <Input type="text" name="firstName" id="firstName" dataUI="txtFirstName" onChange={e => onChange(e, FIRST_NAME)} />
                  </FormGroup>
                  <FormGroup
                    className="mb-2"
                    htmlFor="secondName"
                    isRequired
                    label="Last Name"
                    dataUI="fgLastName"
                    isValid={IsValid(LAST_NAME)}
                    validationMessage={Message.REQUIRED_FIELD}
                  >
                    <Input type="text" name="secondName" id="secondName" dataUI="txtLastName" onChange={e => onChange(e, LAST_NAME)} />
                  </FormGroup>
                  <FormGroup
                    className="mb-2"
                    htmlFor="dpDef"
                    isRequired
                    label="Date of Birth"
                    dataUI="fgDateOfBirth"
                    isValid={IsValid(DATE_OF_BIRTH)}
                    validationMessage={getDateInvalidMessage()}
                  >
                    <Datepicker id="dpDef" dataUI="fgDateOfBirth" onChange={e => onDateChange(e)} format="MM/DD/YYYY" />
                  </FormGroup>
                  <FormGroup
                    className="mb-2"
                    htmlFor="tin"
                    isRequired
                    label="Social Security Number"
                    dataUI="fgLastDigits"
                    isValid={IsValid(TIN)}
                    validationMessage={invalidTINMessage}
                  >
                    <MaskedInput
                      id="tin"
                      dataUI="mtxtLastDigits"
                      className="text-left"
                      pattern={null}
                      type="password"
                      mask={InputMasksConstants.SSN_TAX_ID}
                      onChange={e => onChange(e, TIN)}
                    />
                  </FormGroup>
                </>
              )}
            </Section>
          )}

          {yourInformationState === YOUR_INFORMATION_STATES.CONFIRM_ADDRESS && (
            <Section title="Address" dataUI={`${section.SubSections[0]?.Code}-address-section`}>
              <FormGroup
                className="mb-2"
                htmlFor="address"
                isRequired
                checkboxOrRadio
                label="Is this your current address?"
                dataUI="fgAddress"
                isValid={IsValid(ADDRESS_CONFIRMED)}
                validationMessage={Message.REQUIRED_FIELD}
              >
                <Radio
                  dataUI="addressRadio"
                  htmlFor="addressRadio"
                  id="addressRadio"
                  className="d-inline-block"
                  name="address"
                  value="true"
                  onChange={onAddressChange}
                >
                  {yourInformation.Address}
                </Radio>
                <Radio
                  dataUI="addressRadio2"
                  htmlFor="addressRadio2"
                  id="addressRadio2"
                  className="d-inline-block"
                  name="address"
                  value="false"
                  onChange={onAddressChange}
                >
                  I have a different address.
                </Radio>
              </FormGroup>
            </Section>
          )}

          {yourInformationState === YOUR_INFORMATION_STATES.GREATE_PROCEED && (
            <Section title="Your Information" dataUI={`${section.SubSections[0]?.Code}-proceed-section`}>
              <p>Good News!</p>
              <br />
              <p>
                We recognize you as an existing client. Shortly you will be presented with questions to verify your identity so you can continue the
                application.
              </p>
            </Section>
          )}
        </form>
      )}
    </>
  );
};

export default YourInformation;
