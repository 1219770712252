/* eslint-disable react/prop-types */
/* eslint react/prop-types: "warn" */
import React, { Fragment, useEffect, useState } from 'react';
import _, { partition } from 'lodash';
import {
  FormGroup,
  MultipleFormGroup,
  Radio,
  Input,
  Textarea,
  MaskedInput,
  Datepicker,
  Checkbox,
  Section,
  IntegerInput,
  DataPreviewText,
  AddressAutocomplete,
  Toast
} from '@jkhy/vsg-design-system';
import PickList from '../../../components/PickList/PickList';
import PageSettings_ from './PageHelpers/PageSettings';
import SubSection_ from '../../../data/models/Subsection';
import PageFieldExtended_ from './PageHelpers/PageFieldExtended';
import validateOnBlur, { validateDifferentTIN } from '../../../utils/Validator';
import { AlertTypes, ValueConstants } from '../../../utils/Enums';
import {
  dateFormat, getHoverHelpPageFieldValue, scrollToError, format, deepComparison
} from '../../../utils/Helper';
import PageFieldComponent_ from '../../../data/models/Component';
import { AddressDetails as AddressDetails_ } from '../../../utils/Types';
import QdApplicationHolder from '../../../data/models/QDApplicationHolder';
import Borrower from '../../../data/models/Borrower';
import Messages from '../../../utils/Messages';
import BorrowerBHolder from '../../../data/models/BorrowerHolder';

export interface PageProps<THolder, TSubHolder, TAdditionalData> {
  pageSettings: PageSettings_<THolder, TSubHolder, TAdditionalData>;
  holder: THolder;
  subHolder: TSubHolder;
  formIdentifier: string;
  onChange: (pageFields: PageFieldExtended_<THolder, TSubHolder>, value: string, e: React.ChangeEvent, text: string) => void;
  onAddressSelect?: (pageFields: PageFieldExtended_<THolder, TSubHolder>, value: AddressDetails_, e: React.ChangeEvent, text: string) => void;
  onSubmit?: (invalidPageFields: PageFieldExtended_<THolder, TSubHolder>[], e: React.FormEvent<HTMLFormElement>) => void;
  arePageFieldsSame?: (
    latestPageFields: PageFieldExtended_<THolder, TSubHolder>[],
    currentPageFields: PageFieldExtended_<THolder, TSubHolder>[]
  ) => boolean;
}

const Page = <THolder, TSubHolder, TAdditionalData>(props: PageProps<THolder, TSubHolder, TAdditionalData>) => {
  const passedPageFields = props?.pageSettings?.PageFields;
  const [pageFieldsLocals, setPageFieldsLocals] = useState<PageFieldExtended_<THolder, TSubHolder>[]>(passedPageFields);

  useEffect(() => {
    // TODO: Refactor and move this 3 lines at Identity Verification, use ArePageFieldsSame
    const localReadOnlyFields = pageFieldsLocals.map((pageField: PageFieldExtended_<THolder, TSubHolder>) => pageField.IsReadOnly);
    const passedReadOnlyFields = passedPageFields.map((pageField: PageFieldExtended_<THolder, TSubHolder>) => pageField.IsReadOnly);
    let isEqual = deepComparison(localReadOnlyFields, passedReadOnlyFields);

    if (isEqual && typeof props?.arePageFieldsSame === 'function') isEqual = props?.arePageFieldsSame(pageFieldsLocals, passedPageFields);

    if (!isEqual) {
      setPageFieldsLocals(passedPageFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedPageFields]);

  const onChange = (e, pageField: PageFieldExtended_<THolder, TSubHolder>, component?: PageFieldComponent_) => {
    const {
      target: { value, },
    } = e;

    const pf = { ...pageField, };
    if (component?.ObjectProperty) pf.ObjectProperty = component?.ObjectProperty;
    if (component?.ObjectPropertyStr) pf.ObjectPropertyStr = component?.ObjectPropertyStr;
    if (component?.ObjectType) pf.ObjectType = component?.ObjectType;

    props.onChange(pf, value, e, null);
  };

  const onChangeSelect = (e, pageField: PageFieldExtended_<THolder, TSubHolder>, component?: PageFieldComponent_) => {
    const { value, label, } = e;

    const pf = { ...pageField, };
    if (component?.ObjectProperty) pf.ObjectProperty = component?.ObjectProperty;
    if (component?.ObjectPropertyStr) pf.ObjectPropertyStr = component?.ObjectPropertyStr;
    if (component?.ObjectType) pf.ObjectType = component?.ObjectType;

    props.onChange(pf, value, e, label === ValueConstants.DropDownDefaultValue ? '' : label);
  };

  const onChangeAddress = (value: string, pageField: PageFieldExtended_<THolder, TSubHolder>, component?: PageFieldComponent_) => {
    const pf = pageField;
    if (component?.ObjectProperty) pf.ObjectProperty = component?.ObjectProperty;

    props.onChange(pf, value, null, null);
  };

  const onSelectAddress = (details: AddressDetails_, pageField: PageFieldExtended_<THolder, TSubHolder>, component?: PageFieldComponent_) => {
    const pf = pageField;
    if (component?.ObjectProperty) pf.ObjectProperty = component?.ObjectProperty;

    if (typeof props.onAddressSelect === 'function') props.onAddressSelect(pf, details, null, null);
    else props.onChange(pf, details.fullAddress, null, null);
  };

  const onBlur = (e, pageField: PageFieldExtended_<THolder, TSubHolder>, component?: PageFieldComponent_) => {
    const {
      target: { value, },
    } = e;
    const {
      FieldName, GroupName, IsMultipleForm, Component,
    } = pageField;
    const { inputType, regexStr, errorMessage, } = IsMultipleForm ? component : Component;

    if (inputType === 'email' || !!regexStr) {
      const { IsValid, InvalidMessage, } = validateOnBlur({
        inputType,
        regexStr,
        value,
        errorMessage,
      });

      setPageFieldsLocals(
        pageFieldsLocals.map(p => {
          const currentPf = p;
          const invalidField = currentPf.FieldName === FieldName && currentPf.GroupName === GroupName;
          if (invalidField) {
            currentPf.IsInvalidResult = !IsValid;
            currentPf.ValidationMessage = InvalidMessage;
          }
          return currentPf;
        })
      );

      const checkBusinessTin = IsValid && FieldName === 'TaxID' && GroupName === 'Business Information/General';
      if (checkBusinessTin) {
        const { holder, subHolder, } = props;
        const { Type, } = validateDifferentTIN(
            holder as QdApplicationHolder, 
            (subHolder as BorrowerBHolder).Business, 
            {...pageField, Component: undefined } as PageFieldExtended_<QdApplicationHolder, Borrower>, 
            holder as QdApplicationHolder
          );
          
        if (Type === AlertTypes.Warning) Toast.warning(Messages.BUSINESS_TIN_SAME_AS_MAIN_APPLICANT);
      }
    }

    if (inputType === 'percent' && component?.type === 'maskedinput') {
      onChange(e, pageField, component);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { pageSettings, holder, subHolder, } = props;
    const pageFields: PageFieldExtended_<THolder, TSubHolder>[] = pageSettings.PageFields;
    const [invalidPageFields] = partition(pageFields, pf => !pf.IsValid(holder, subHolder, pf) && pf.Component !== null);
    if (invalidPageFields.length > 0) {
      scrollToError(document.querySelector(`[data-ui="${invalidPageFields[0].dataUI}"]`));
      setPageFieldsLocals(
        pageFields.map(p => {
          const currentPf = p;
          const invalidField = invalidPageFields.find(invalidPf => invalidPf.FieldName === p.FieldName && invalidPf.GroupName === p.GroupName);
          if (!currentPf.IsMultipleForm) currentPf.IsInvalidResult = !!invalidField;
          return currentPf;
        })
      );
    }
    props.onSubmit(invalidPageFields, e);
  };

  const initValue = (pageField: PageFieldExtended_<THolder, TSubHolder>, component?: PageFieldComponent_) => {
    const { subHolder, } = props;

    const objectProperty = component?.ObjectProperty ?? pageField.ObjectProperty;
    const objectType = component?.ObjectType ?? pageField?.ObjectType;
    if (objectType) {
      return pageField.ObjectIndex ? subHolder[objectType]?.[pageField.ObjectIndex]?.[objectProperty] : subHolder[objectType][objectProperty];
    }
    //  TODO: Could expand returned value based on field type.
    //  Ex: currency format(value, 'c', 2)
    return subHolder[objectProperty];
  };

  const getClassName = isInvalidResult => (isInvalidResult ? 'invalid' : '');

  const renderSelect = (component: PageFieldComponent_, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField, component);

    const selectOptions = component.options.initialData || [];
    return (
      <PickList
        dsSelectWrapper
        id={pageField.FieldName}
        stateLess={component.options.stateLess}
        hideSelectOption={component.options.hideSelectOption}
        useDescrip={component.options?.useDescrip}
        value={value}
        initialData={selectOptions}
        disabled={pageField.IsDisabled}
        onChange={e => onChangeSelect(e, pageField, component)}
        ListName={component.options.ListName}
        ListType={component.options.ListType}
        pageField={pageField}
        className={getClassName(pageField.IsInvalidResult || component?.IsInvalidResult)}
        dataUI={pageField.dataUI}
      />
    );
  };

  const renderRadio = (component: any, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const { Component, }: PageFieldExtended_<THolder, TSubHolder> = pageField;
    const value = initValue(pageField);
    const className = Component.options.length > 1 && !Component.preventRadioInline ? 'd-inline-block' : '';
    const convertValueToStr = typeof value === 'boolean' || typeof value === 'bigint' || typeof value === 'number';
    return (
      <div>
        {Component.options.map((option, index) => (
          <Radio
            key={`${option.value}-${option.label}`}
            // required={pageField.Required} We need to suppress native behavior of submit form
            htmlFor={`${pageField.FieldName}-${index}`}
            id={`${pageField.FieldName}-${index}`}
            value={option.value}
            disabled={pageField.IsDisabled}
            name={`${pageField.FieldName}-${option.label}`}
            onChange={e => onChange(e, pageField)}
            className={`${className} ${getClassName(pageField.IsInvalidResult)}`}
            checked={convertValueToStr ? value.toString() === option.value : value === option.value}
            dataUI={pageField.dataUI}
          >
            {option.label}
          </Radio>
        ))}
      </div>
    );
  };

  const renderInput = (component: PageFieldComponent_, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField);
    return (
      <Input
        id={pageField.FieldName}
        dataUI={pageField.dataUI}
        disabled={pageField.IsDisabled}
        onChange={e => onChange(e, pageField)}
        onBlur={e => onBlur(e, pageField)}
        value={value || ''}
        className={getClassName(pageField.IsInvalidResult)}
        maxLength={component?.maxLength}
        min={component?.minValue}
        max={component?.maxValue}
        type={component?.inputType}
      />
    );
  };

  const renderDatePicker = (pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField);

    return (
      <Datepicker
        id={pageField.FieldName}
        value={value}
        format="MM/DD/YYYY"
        disabled={pageField.IsDisabled}
        rangeStart={pageField?.Component?.rangeStart}
        rangeEnd={pageField?.Component?.rangeEnd}
        onChange={(e: any) => {
          const formattedDate = dateFormat(e?.target?.value);
          onChange({ target: { value: formattedDate, }, }, pageField);
        }}
        // This function is triggered when date is out of range
        onInvalidDate={(isDateFormatValid: boolean, isDateInRange: boolean, currentValue: Date) => {
          if (isDateFormatValid && pageField?.Component?.notClearableOnInvalid) {
            const formattedDate = dateFormat(currentValue);
            onChange({ target: { value: formattedDate, }, }, pageField);
          }
        }}
        className={getClassName(pageField.IsInvalidResult)}
        dataUI={pageField.dataUI}
        notClearableOnInvalid={pageField?.Component?.notClearableOnInvalid || false}
      />
    );
  };

  const renderMaskedInput = (component: PageFieldComponent_, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField, component);
    const emptyPattern = null;

    const invalidResultClassName = getClassName(pageField.IsInvalidResult || component?.IsInvalidResult);
    const className = component?.className ? `${component?.className} ${invalidResultClassName}` : invalidResultClassName;

    // ToDo
    // New properties have been added with the latest version (minPercent and maxPercent)
    // Only for "percent" type - If you want to use these new properties,
    // please update the field value when using onBlur event (You can use functionality from onChange method)
    return (
      <MaskedInput
        id={component?.FieldName ?? pageField.FieldName}
        value={value ?? ''}
        onChange={e => onChange(e, pageField, component)}
        onBlur={e => onBlur(e, pageField, component)}
        min={component?.minValue}
        max={component?.maxValue}
        maxLength={component?.maxLength}
        mask={component?.inputMask}
        disabled={pageField.IsDisabled}
        type={component?.inputType}
        icon={component?.icon}
        pattern={emptyPattern}
        placeholder={component?.placeholder}
        className={className}
        dataUI={component?.dataUI ?? pageField.dataUI}
        guide={component?.guide}
      />
    );
  };

  const renderIntegerInput = (component: any, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField);
    return (
      <IntegerInput
        id={pageField.FieldName}
        disabled={pageField.IsDisabled}
        hideArrows={component?.hideArrows}
        min={component?.minValue}
        max={component?.maxValue}
        maxLength={component?.maxLength}
        onChange={e => onChange(e, pageField)}
        value={value}
        className={getClassName(pageField.IsInvalidResult)}
        dataUI={pageField.dataUI}
      />
    );
  };

  const renderCheckbox = (component: PageFieldComponent_, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    let value = initValue(pageField);
    if (!!value && typeof value !== 'boolean') {
      value = `${value}` === 'true';
    }

    return (
      <Checkbox
        htmlFor={pageField.FieldName}
        id={pageField.FieldName}
        checked={value}
        disabled={pageField.IsDisabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange({ target: { value: e.target.checked, }, }, pageField)}
        className={getClassName(pageField.IsInvalidResult)}
        dataUI={pageField.dataUI}
      >
        {component?.checkBoxLabel}
      </Checkbox>
    );
  };

  const renderText = (pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField);
    return (
      <label>
        <b>{value}</b>
      </label>
    );
  };

  const renderTextarea = (component: PageFieldComponent_, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField);
    return (
      <Textarea
        id={pageField.FieldName}
        disabled={pageField.IsDisabled}
        onChange={e => onChange(e, pageField)}
        value={value || ''}
        className={getClassName(pageField.IsInvalidResult)}
        dataUI={pageField.dataUI}
        rows={component?.rows}
        maxLength={component?.maxLength}
      />
    );
  };

  const renderDataPreview = (pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    let value = initValue(pageField);

    // Format read-only
    if (value && pageField.IsReadOnly) {
      // TIN Value
      if (pageField.ObjectProperty === 'TIN') {
        const lastFourDigits = value.substring(value.length - 4);
        value = `******-${lastFourDigits}`;
      }
      // Date value MM/DD/YYYY
      if (pageField?.Component?.type === 'datepicker') {
        value = dateFormat(value);
      }

      if (pageField?.Component?.inputType === 'percent') {
        value = format(value, 'p', 3);
      }
    }
    return <DataPreviewText className="mb-2" label={pageField.Label} text={value} />;
  };

  const renderAddressAutocomplete = (component: PageFieldComponent_, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField) || '';

    return (
      <AddressAutocomplete
        type="text"
        name="current-address"
        className={getClassName(pageField.IsInvalidResult)}
        id={pageField.FieldName}
        dataUI={pageField.dataUI}
        value={value}
        onBlur={e => onBlur(e, pageField)}
        onChange={(addressDetails: any) => onChangeAddress(addressDetails, pageField, component)}
        onAddressSelect={(addressDetails: any) => onSelectAddress(addressDetails, pageField, component)}
      />
    );
  };

  const renderComponent = (component: PageFieldComponent_, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    switch (component?.type) {
      case 'select':
        return renderSelect(component, pageField);
      case 'text':
        return renderText(pageField);
      case 'radio':
        return renderRadio(component, pageField);
      case 'input':
        return renderInput(component, pageField);
      case 'textarea':
        return renderTextarea(component, pageField);
      case 'datepicker':
        return renderDatePicker(pageField);
      case 'maskedinput':
        return renderMaskedInput(component, pageField);
      case 'integerInput':
        return renderIntegerInput(component, pageField);
      case 'checkbox':
        return renderCheckbox(component, pageField);
      case 'address':
        return renderAddressAutocomplete(component, pageField);
      default:
        return renderInput(component, pageField);
    }
  };

  const renderSubSectionPageFields = (subSection: SubSection_) => {
    let pageFields: PageFieldExtended_<THolder, TSubHolder>[] = pageFieldsLocals;

    if (subSection.GroupName) {
      pageFields = _.filter(pageFields, p => p.GroupName === subSection.GroupName);
    }
    pageFields = pageFields.sort((a, b) => a.SortOrder - b.SortOrder);

    const renderComponents = pf => {
      const components = pf.FormComponentsVisibility ? pf.FormComponentsVisibility(props.holder, props.subHolder, pf) : pf.Components;
      const res = components.map((c: PageFieldComponent_) => {
        return {
          element: (
            <FormGroup
              isMultipleGroup
              label={c.Label}
              isBold={pf.IsBold}
              isRequired={pf.Required}
              hoverHelp={getHoverHelpPageFieldValue(pf)}
              isValid={!c?.IsInvalidResult}
              //  validationMessage={pf.ValidationMessage}
              dataUI={pf.dataUI}
              htmlFor={pf.FieldName}
              key={`${c.dataUI}-wrapper`}
            >
              {renderComponent(c, pf)}
            </FormGroup>
          ),
          isValid: !c?.IsInvalidResult,
          validationMessage: c?.IsInvalidResult ? c?.ValidationMessage : '',
        };
      });
      return res;
    };

    return pageFields.map(pf => {
      return (
        // eslint-disable-next-line react/jsx-fragments
        <Fragment key={`${pf?.Id}-${pf?.FieldName}`}>
          {!(pf.IsHidden || (pf.IsHiddenCalculated && pf.IsHiddenCalculated(props.holder, props.subHolder, pf))) && (
            <>
              {pf.IsMultipleForm && (
                <MultipleFormGroup
                  className="mb-2"
                  multiFormGroupProps={{
                    label: pf.Label,
                    isHidden: pf.IsHidden,
                    // It's always to true, because we want to remove "(optional)" text from label,
                    // validation is triggered by child components (groupElements)
                    isRequired: true,
                  }}
                  groupColProps={{
                    xs: '12',
                  }}
                  groupElements={renderComponents(pf)}
                />
              )}
              {pf.IsReadOnly && renderDataPreview(pf)}
              {!pf.IsMultipleForm && !pf.IsReadOnly && (
                <FormGroup
                  className="mb-2"
                  label={pf.Label}
                  checkboxOrRadio={pf?.Component?.type === 'checkbox' || pf?.Component?.type === 'radio'}
                  isBold={pf.IsBold}
                  isRequired={pf.Required}
                  hoverHelp={getHoverHelpPageFieldValue(pf)}
                  isValid={!pf.IsInvalidResult}
                  validationMessage={pf.ValidationMessage}
                  dataUI={pf.dataUI}
                  htmlFor={pf.FieldName}
                >
                  {renderComponent(pf.Component, pf)}
                </FormGroup>
              )}
            </>
          )}
        </Fragment>
      );
    });
  };

  const renderSubSections = (subSection: SubSection_[]) => {
    if (!subSection.length) {
      return null;
    }
    return (
      <>
        {subSection.map(ss => (
          <Section
            key={`${ss?.Code}-${ss?.Id}-${ss?.SubSectionName}`}
            dataUI={ss.dataUI}
            title={ss.SubSectionName}
            headerText={ss.SubSectionHeaderText}
            footerText={ss.SubSectionFooterText}
          >
            {renderSubSectionPageFields(ss)}
          </Section>
        ))}
      </>
    );
  };

  const { pageSettings, formIdentifier, } = props;

  if (pageSettings.PageFields.length <= 0 || !pageSettings.PageSection) {
    return null;
  }

  return (
    <form id={formIdentifier} onSubmit={onSubmit} data-ui={formIdentifier} noValidate>
      {renderSubSections(pageSettings.PageSection)}
    </form>
  );
};

export default Page;
