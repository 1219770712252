// eslint-disable-next-line max-classes-per-file
import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings from '../../../Page/PageHelpers/PageSettings';
import PageField_ from '../../../../../data/models/PageField';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import Borrower_ from '../../../../../data/models/Borrower';
import QDCoBorrowerApplicationHolder_ from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import InputMasksConstants from '../../../../../utils/InputMasksConstants';
import { booleanStringComparison } from '../../../../../utils/Helper';

const pageFieldsMapper = <THolder, TSubHolder>(
  pageField: PageField_,
  getDefault: () => PageFieldExtended_<THolder, TSubHolder>
): PageFieldExtended_<THolder, TSubHolder> => {
  if (!pageField) {
    return null;
  }

  switch (pageField.FieldName) {
    case 'AGI':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'AGI',
        ObjectPropertyType: 'string',
        Component: {
          type: 'maskedinput',
          inputType: 'number',
          icon: IconsSolid.faDollarSign,
          maxLength: 11,
          maxValue: 999999999,
        },
        dataUI: 'business-borrower-AGI-field',
      });
    case 'AnnualSales':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'AnnualSales',
        ObjectPropertyType: 'string',
        Component: {
          type: 'maskedinput',
          inputType: 'number',
          icon: IconsSolid.faDollarSign,
          maxLength: 11,
          maxValue: 999999999,
        },
        dataUI: 'business-borrower-annual-sales-field',
      });
    case 'AverageDeposits':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'AverageDeposits',
        ObjectPropertyType: 'string',
        Component: {
          type: 'maskedinput',
          inputType: 'number',
          icon: IconsSolid.faDollarSign,
          maxLength: 11,
          maxValue: 999999999,
        },
        dataUI: 'business-borrower-average-deposits-field',
      });
    case 'NumberOfEmployees':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'NumberOfEmployees',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          maxLength: 9,
          inputType: 'number',
          minValue: 0,
          maxValue: 9999999,
        },
        dataUI: 'business-borrower-number-of-employees-field',
      });
    case 'MostRecentYearEndDate':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'MostRecentYearEndDate',
        ObjectPropertyType: 'string',
        Component: {
          type: 'datepicker',
        },
        dataUI: 'business-borrower-most-recent-year-end-date-field',
      });

    // Custom fields
    case 'ExistingAccount':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'IsExistingCustomerFlag',
        ObjectPropertyType: 'string',
        Component: {
          type: 'radio',
          options: [
            { value: 'true', label: 'Yes', },
            { value: 'false', label: 'No', }
          ],
        },
        dataUI: 'business-borrower-existing-customer-field',
        Label: 'Existing Deposit Account?',
      });
    case 'CheckingAccountNumber':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'CheckingAccountNumber',
        ObjectPropertyType: 'string',
        IsHiddenCalculated: (holder, subHolder) => !booleanStringComparison(subHolder?.IsExistingCustomerFlag),
        Component: {
          type: 'maskedinput',
          inputMask: InputMasksConstants.CHECKING_ACCOUNT_NUMBER,
          guide: false,
          className: 'text-left',
        },
        dataUI: 'business-borrower-checking-account-number-field',
        Label: 'Business Checking Account #',
      });
    case 'ReCheckingAccountNumber':
      return PageFieldExtended_.createInstance({
        ...pageField,
        Label: 'Re-enter Checking Account #',
        ObjectProperty: 'ReCheckingAccountNum',
        ObjectPropertyType: 'string',
        IsHiddenCalculated: (holder, subHolder) => !booleanStringComparison(subHolder?.IsExistingCustomerFlag),
        Component: {
          type: 'maskedinput',
          inputMask: InputMasksConstants.CHECKING_ACCOUNT_NUMBER,
          guide: false,
          className: 'text-left',
        },
        IsValid: (coBorrowerAddNowHolder, subHolder, pageF) => {
          let result = true;
          if (pageF.IsHiddenCalculated(coBorrowerAddNowHolder, subHolder, pageField)) return result;

          const value = subHolder[pageF.ObjectProperty];
          const compareValue = subHolder.CheckingAccountNumber;

          if (compareValue) {
            result = !value ? false : value.trim() === compareValue.trim();
          }
          const pf = pageF;
          pf.ValidationMessage = result ? '' : 'Account numbers must match.';

          return result;
        },
        dataUI: 'business-borrower-re-checking-account-number-field',
      });

    default:
      return getDefault();
  }
};

export default class OtherInformationSettings extends PageSettings<CoBorrowerAddNowHolder_, Borrower_, boolean> {
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<CoBorrowerAddNowHolder_, Borrower_> {
    return pageFieldsMapper(pageField, () => this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField));
  }
}

export class CoBorrowerBusinessOtherInformationSettings extends PageSettings<QDCoBorrowerApplicationHolder_, Borrower_, boolean> {
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_> {
    return pageFieldsMapper(pageField, () => this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField));
  }
}
